import { getHttp } from "../http-common";

export function getProductAll(orderBy, page, limit) {
  return getHttp().get(
    `/products/list?order=${orderBy ? orderBy : "desc"}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 3}`
  );
}

export function getProductSaleAll(limit, orderBy) {
  return getHttp().get(
    "/products/salelist?perpage=" + limit + "&order=" + orderBy
  );
}

export function getCmsProductAll() {
  return getHttp().get("/products/cms/list");
}

export function getProductByBrand(brand_slug, orderBy, page, limit) {
  return getHttp().get(
    `/products/list?brand=${brand_slug}&order=${
      orderBy ? orderBy : "desc"
    }&page=${page ? page : 1}&limit=${limit ? limit : 3}`
  );
}

export function getCmsProductListByBrandId(brand_id) {
  return getHttp().get(`/products/cms/brand/${brand_id}`);
}

export function getCmsProductByBrandSlug(brand_slug) {
  return getHttp().get(`/products/cms/brand/search/${brand_slug}`);
}

export function getProductByBrandSlug(brand_slug) {
  return getHttp().get(`/products/brand/search/${brand_slug}`);
}

export function getProductDetail(id, token) {
  return getHttp().get(`/products/${id}`, { token: token });
}

export function createProduct(data) {
  return getHttp().post("/products/cms/create", data);
}

export function updateProduct(data) {
  return getHttp().post(`/products/cms/update`, data);
}

export function deleteProduct(id) {
  return getHttp().delete(`/products/cms/${id}`);
}
