<template>
  <nav class="w-full nav-bg text-white px-5 lg:px-0">
    <div class="header lg:flex flex-col w-full hidden">
      <div class="flex flex-row text-white w-full h-1/2 px-48 pt-6">
        <div class="w-48">
          <a href="/">
            <img class="h-full" src="@/assets/icon/logoImage.png" />
          </a>
        </div>
        <div class="flex-none w-52">
          <span>
            เราจัดจำหน่ายรถยนต์มือสอง
            <br />ด้วยความซื่อสัตย์โปร่งใส
            <br />เพื่อให้ลูกค้าได้รถที่ดีมีคุณภาพ
          </span>
        </div>
      </div>
      <div class="flex flex-row text-white w-full h-1/2 text-xl px-28">
        <div class="w-3/4 flex flex-row justify-between xl:mx-28 lg:24">
          <a href="/products">
            <div class="h-full inline-block">
              <span class="align-middle">รุ่นรถทั้งหมด</span>
            </div>
          </a>
          <a href="/about_us">
            <div class="h-full inline-block">
              <span class="align-middle">เกี่ยวกับเรา</span>
            </div>
          </a>
          <a href="/document">
            <div class="h-full inline-block">
              <span class="align-middle">เอกสารประกอบการซื้อ</span>
            </div>
          </a>
          <a href="/contact_us">
            <div class="h-full inline-block">
              <span class="align-middle">ติดต่อเรา</span>
            </div>
          </a>
        </div>
        <ContactButton />
      </div>
    </div>
    <ToggleHeader />
    <div id="header-mobile" class="block lg:hidden mx-auto px-4"></div>

    <div id="mobile-menu" class="header mobile-menu flex hidden flex-col px-5">
      <ToggleHeader />
      <div>
        <a href="/products">
          <div class="h-full inline-block">
            <span class="align-middle">รุ่นรถทั้งหมด</span>
          </div>
        </a>
      </div>
      <div>
        <a href="/about_us">
          <div class="h-full inline-block">
            <span class="align-middle">เกี่ยวกับเรา</span>
          </div>
        </a>
      </div>
      <div>
        <a href="/document">
          <div class="h-full inline-block">
            <span class="align-middle">เอกสารประกอบการซื้อ</span>
          </div>
        </a>
      </div>
      <div>
        <a href="/contact_us">
          <div class="h-full inline-block">
            <span class="align-middle">ติดต่อเรา</span>
          </div>
        </a>
      </div>
      <ContactButton style="height: 112px" />
    </div>
  </nav>
</template>

<script>
import ContactButton from "@/components/ContactButton.vue";
import ToggleHeader from "./components/ToggleHeader.vue";

export default {
  components: {
    ContactButton,
    ToggleHeader,
  },
  methods: {},
};
</script>

<style scoped>
nav {
  z-index: 1000;
}

.nav-bg {
  background-color: rgb(28, 41, 80);
}

.nav-bar .router-link-active {
  font-weight: 700;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, 100);
}

.mobile-menu {
  height: auto;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgb(28, 41, 80);
  overflow-x: hidden;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

/* The navigation menu links */
.mobile-menu .page-link {
  padding: 8px 2px 8px 32px;
  text-decoration: none;
  color: #818181;
  display: block;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

a div span {
  height: 7rem;
  line-height: 7rem;
  text-align: center;
  padding: 1rem;
}
</style>
