<template>
  <div
    class="bg-gray-100 hover:shadow-inner rounded-md shadow hover:shadow-2xl cursor-pointer m-0 md:m-10 p-4 rounded-lg"
    v-on:click="select"
  >
    <div class="flex justify-center">
      <img class="w-48 h-48" :src="filePath" />
    </div>
    <div>
      <h5 class="font-bold text-lg">{{ brandName }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemId: {
      type: [Number],
    },
    filePath: {
      type: [String],
      default: "",
    },
    brandName: {
      type: [String],
      default: "",
    },
  },
  methods: {
    select: function () {
      window.location.assign(`/products?brand=${this.brandName}`);
    },
  },
};
</script>
