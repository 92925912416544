import { createStore } from "vuex";

export default createStore({
  state: {
    brands: [],
    products: [],
    product: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
