<template>
  <div class="footer flex flex-col w-full p-5">
    <div class="pb-5">
      <div class="py-5">
        <p class="text-2xl font-bold lg:text-3xl">รถมือสองราชประดิษฐ์ออโต้</p>
      </div>
      <div>
        <p>ที่อยู่ : 1/1 ถนนมีนบุรี แขวงมีนบุรี เขตมีนบุรี กรุงเทพ 10510</p>
        <p>1/1 Nimit Mai Rd, Khwaeng Min Buri, Min Buri, Bangkok 10510</p>
        <a
          :href="'tel:' + (mobilePhone && mobilePhone.detail)"
          variant="link"
          >{{ `เบอร์โทรศัพท์: ${mobilePhone && mobilePhone.detail}` }}</a
        >
      </div>
    </div>
    <ContactButton />
  </div>
</template>

<script>
import { getByType } from "@/api/contact";
import ContactButton from "@/components/ContactButton.vue";

export default {
  components: {
    ContactButton,
  },
  data() {
    return {
      mobilePhone: "",
    };
  },
  async mounted() {
    await this.setMobile();
  },
  methods: {
    async setMobile() {
      const mobileResponse = await getByType("mobile");
      const mobileData = await mobileResponse.data;
      this.mobilePhone = mobileData[0];
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: rgb(182, 195, 232);
}
</style>
