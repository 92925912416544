import axios from "axios";
import { base_url } from "../config/config";

export function getHttp(header) {
  const options = {
    baseURL: base_url + "/v1",
  };
  if (header) options.headers = header;

  return axios.create(options);
}
