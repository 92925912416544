<template>
  <div
    class="
      relative
      flex flex-row
      h-full
      w-full
      items-center
      lg:hidden
      xl:hidden
      py-5
    "
  >
    <div class="absolute flex items-start">
      <button
        id="mobile-menu-button"
        class="mobile-menu-button outline-none"
        @click="onToggle"
      >
        <svg
          class="w-12 h-12 text-gray-500 hover:text-green-500"
          x-show="!showMenu"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
    <div class="flex w-full justify-center">
      <div>
        <a href="/">
          <img class="h-12 lg:h-24" src="@/assets/icon/logoImage.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    onToggle() {
      document.getElementById("mobile-menu").classList.toggle("hidden");
    },
  },
};
</script>
