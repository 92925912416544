<template>
  <div v-on:click="select" class="w-full lg:w-1/4 p-4">
    <div
      class="relative card p-4 w-full h-full bg-blue-100 rounded-md shadow hover:shadow-2xl cursor-pointer"
    >
      <!-- <div class="title absolute bottom-0 right-0 opacity-75">
      <span>{{ productName }}</span>
      </div>-->
      <div class="flex justify-center">
        <img :src="filePath" />
      </div>
      <div class="box pt-4">
        <p class="font-bold">{{ productName }}</p>
      </div>
      <div>
        <h5>{{ brandName }}</h5>
      </div>
    <div>
      <p class="text-red-600 font-bold" v-if="priceSale">
        {{ priceSale }}
      </p>
    </div>
    <div>
      <p
        v-if="productPrice"
        :class="priceSale ? 'line-through text-sm' : 'font-bold'"
      >
        {{ productPrice }}
      </p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemId: {
      type: [Number],
    },
    filePath: {
      type: [String],
      default: "",
    },
    productName: {},
    priceSale: {
      type: [String],
      default: null,
    },
    productPrice: {
      type: [String],
      default: "",
    },
    brandName: {
      type: [String],
      default: "",
    },
  },
  methods: {
    select() {
      window.location.assign(`/product?id=${this.itemId}`);
    },
  },
};
</script>

<style scoped>
.box p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
