import { getHttp } from "../http-common";

export function getBrandAll() {
  return getHttp().get("/brands/list");
}

export function getBrandDetail(id) {
  return getHttp().get(`/brands/${id}`);
}

export function getBrandBySlug(slug) {
  return getHttp().get(`/brands/search/${slug}`);
}
