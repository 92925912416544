import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home/index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/document",
    name: "Document",
    component: () => import("../views/Document.vue"),
  },
  {
    path: "/about_us",
    name: "About",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/contact_us",
    name: "Contact",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/product/list/index.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("../views/product/detail/index.vue"),
    props: (route) => ({ query: route.query.id }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
