<template>
  <div class="home flex flex-col mb-10">
    <div>
      <a
        :href="facebook && `https://www.facebook.com/${facebook.detail}`"
        target="_blank"
        variant="link"
      >
        <img
          alt="Banner"
          :src="banner"
          class="object-fit h-48 lg:h-full w-full"
        />
      </a>
    </div>
    <div id="brandList">
      <div class="title-bg rounded-lg my-5 py-5">
        <h3 class="font-bold text-xl">ยี่ห้อรถ</h3>
      </div>

      <div class="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
        <BrandCard
          :href="`/product?id=${item.id}`"
          v-for="(item, index) in brands"
          :key="index"
          :filePath="item.file_path"
          :brandName="item.brand_name"
        />
      </div>
    </div>
    <div class="flex flex-col" id="saleList">
      <div class="title-bg rounded-lg my-5 py-5">
        <h3 class="font-bold text-xl">ราคาพิเศษ</h3>
      </div>
      <div class="flex flex-col bg-gray-100">
        <div
          class="flex flex-col justify-center lg:flex-row lg:justify-start lg:flex-wrap"
        >
          <ProductCard
            v-for="(item, index) in saleList"
            :key="index"
            :itemId="item.id"
            :filePath="item.file_path"
            :productName="item.product_name"
            :brandName="item.brand_name"
            :priceSale="item.price_sale"
            :productPrice="item.price"
          />
        </div>
        <div class="pb-4">
          <a class="underline" href="/products">ดูรุ่นรถทั้งหมด</a>
        </div>
      </div>
    </div>
    <div id="reviewList">
      <div class="title-bg rounded-lg my-5 py-5">
        <h3 class="font-bold text-xl">Video</h3>
      </div>
      <div
        class="h-1/2 grid grid-cols-1 md:grid-cols-3 py-10 justify-items-center bg-gray-100"
      >
        <div
          class="p-4"
          v-for="(link, index) in youtubeList"
          :key="`youtube-${index}`"
        >
          <iframe
            class="h-48 w-auto"
            :src="link"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div id="insurance">
      <div class="title-bg rounded-lg my-5 py-5">
        <h3 class="font-bold text-xl">คุณภาพและการรับประกัน</h3>
      </div>
      <div class="flex py-5 lg:py-20 px-10 lg:px-80 bg-gray-100">
        <div class="w-full">
          <img
            class="object-contain h-6/12 w-full"
            src="https://firebasestorage.googleapis.com/v0/b/rajpraditauto.appspot.com/o/files%2Finsurance.jpg?alt=media&token=9a5bf4f2-34b8-41e3-8893-b8ab8552da0d"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByType } from "@/api/contact";
import { getBrandAll } from "@/api/brand";
import { getProductSaleAll } from "@/api/product";
import ProductCard from "@/components/ProductCard.vue";
import BrandCard from "@/components/BrandCard.vue";

export default {
  name: "Home",
  data() {
    return {
      brands: [],
      saleList: [],
      facebook: "",
      banner:
        "https://firebasestorage.googleapis.com/v0/b/rajpraditauto.appspot.com/o/files%2F20075172021090110174.jpg?alt=media&token=56c69afb-ff83-4c4b-b8da-26599a8bb584",
      youtubeList: [
        "https://www.youtube.com/embed/Se15PpvdPz4",
        "https://www.youtube.com/embed/Se15PpvdPz4",
        "https://www.youtube.com/embed/Se15PpvdPz4",
      ],
    };
  },
  components: { ProductCard, BrandCard },
  computed: {},
  methods: {
    async setSaleList() {
      const saleResponse = await getProductSaleAll(6, "asc");
      const sales = await saleResponse.data;
      this.saleList = sales;
    },
    async setBrandList() {
      const brandResponse = await getBrandAll();
      const brands = await brandResponse.data;
      this.brands = brands;
    },
    async setFacebook() {
      const facebookResponse = await getByType("facebook");
      const facebookData = await facebookResponse.data;
      this.facebook = facebookData[0];
    },
  },
  async mounted() {
    await this.setBrandList();
    await this.setSaleList();
    await this.setFacebook();
  },
};
</script>

<style scoped>
.title-bg {
  background-color: rgb(28, 41, 80);
  color: white;
}
</style>
