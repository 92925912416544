import { getHttp } from "../http-common";

export function getAll() {
    return getHttp().get("/contacts");
}

export function get(id) {
    return getHttp().get(`/contacts/${id}`);
}

export function getByType(type) {
    return getHttp().get(`/contacts/type/${type}`);
}
