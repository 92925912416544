<template>
  <div class="box flex flex-row items-center justify-center">
    <a :href="line && line.detail" target="_blank" variant="link"><div class="rounded-full line-icon mx-2"></div></a>
    <a :href="facebook && `https://www.facebook.com/${facebook.detail}`" target="_blank" variant="link"><div class="rounded-full facebook-icon mx-2"></div></a>
    <a :href="website ? website : ('https://www.google.co.th/maps/@'+latitude + ',' + longtitude)"  variant="link"><div class="rounded-full invert map-icon mx-2"></div></a>
    <a :href="'tel:'+(mobilePhone && mobilePhone.detail)" variant="link"><div class="rounded-full invert phone-icon mx-2"></div></a>
  </div>
</template>

<script>
import { getByType } from "@/api/contact";
import { getAddressAll } from "@/api/address";

export default {
  data() {
    return {
      line : '',
      facebook : '',
      website: '',
      latitude: '',
      longtitude: '',
      mobilePhone : ''
    }
  },
  components: {},
  async mounted() {
    await this.setLine()
    await this.setFacebook()
    await this.setMobile()
    await this.setAddress()
  },
  methods: {
    async setLine() {
      const lineResponse = await getByType("line");
      const lineData = await lineResponse.data;
      this.line = lineData[0];
    },
    async setFacebook() {
      const facebookResponse = await getByType("facebook");
      const facebookData = await facebookResponse.data;
      this.facebook = facebookData[0];
    },
    async setMobile() {
      const mobileResponse = await getByType("mobile");
      const mobileData = await mobileResponse.data;
      this.mobilePhone = mobileData[0];
    },
    async setAddress() {
      const res_address = await getAddressAll()
			const addresses = await res_address.data;
			if (addresses && addresses[0]) {
				if (addresses[0].lat) this.Latitude = addresses[0].lat
				if (addresses[0].long) this.longtitude = addresses[0].long
				if (addresses[0].website) this.website = addresses[0].website
			}
    },
  },
};
</script>

<style scoped>
.footer {
  text-align: center;
}

.box .line-icon {
  background-image: url("../assets/icon/line.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}

.box .facebook-icon {
  background-image: url("../assets/icon/facebook.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.box .phone-icon {
  background-image: url("../assets/icon/phone-call.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
.box .map-icon {
  background-image: url("../assets/icon/map-marker.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.header .invert {
  filter: invert(1);
}

.spacing {
  gap: 32px;
}
</style>
